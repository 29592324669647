@media only screen and (min-width: 375px) {

	/*Plus de 375px*/
	.login-page {
		width: 360px;
		padding: 8% 0 0;
		margin: auto;
	}

	.form {
		background-color: var(--body);
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
		position: relative;
		z-index: 1;
		max-width: 360px;
		margin: 0 auto 0;
		padding: 45px 45px 45px 0;
		text-align: center;
	}
}

@media only screen and (max-width: 375px) {

	/*Moins de 375px*/
	.login-page {
		width: 100vw;
		padding: 8% 0 0;
	}

	.form {
		background-color: var(--body);
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
		position: relative;
		z-index: 1;
		max-width: 360px;
		margin: 0 auto 0;
		padding: 9vw;
	}
}

/* --- Button --- */
button {
	background: none;
	color: inherit;
	display: block;
	cursor: inherit;
	height: 42px;
	text-align: inherit;
	font: inherit;
}

button:disabled {
	background-color: #606060 !important;
}

/* --- Button color --- */
button.red,
a.btn.red {
	background-color: var(--red-primary);
	color: #fff;
}

button.red:hover,
a.btn.red:hover {
	background-color: var(--red-secondary);
}

button.blue,
a.btn.blue {
	background-color: var(--blue-primary);
	color: #fff;
}

button.blue:hover,
a.btn.blue:hover {
	background-color: var(--blue-secondary);
}

button.green,
a.btn.green {
	background-color: var(--green-primary);
	color: #fff;
}

button.green:hover,
a.btn.green:hover {
	background-color: var(--green-secondary);
}

button.orange,
a.btn.orange {
	background-color: var(--orange-primary);
	color: #fff;
}

button.orange:hover,
a.btn.orange:hover {
	background-color: var(--orange-secondary);
}

/* --- button --- */
button,
a.btn {
	display: flex;
	height: 42px;
	width: 100%;
	border-radius: 5px;
	outline: 0;
	cursor: pointer;

	justify-content: center;
	align-items: center;
	border: 2px solid #4f474e00;
	margin-bottom: 1px;
	box-sizing: border-box;
	gap: 10px
}

button:active,
a.btn:active {
	margin-top: 1px;
	margin-bottom: 0px;
}

button:hover,
button:active,
button:focus,
a.btn:hover,
a.btn:active,
a.btn:focus {
	border: 2px solid #4f474e61;
	background-color: var(--button-back-secondary);
}

button.left,
a.btn.left {
	justify-content: left !important;
}

button.right,
a.btn.right {
	justify-content: right !important;
}

button img,
a.btn img {
	max-width: 30px;
}

button.fluent_btn span,
a.btn.fluent_btn span {
	opacity: 0.5;
	transition: opacity .5s
}

button.fluent_btn:hover span,
a.btn.fluent_btn:hover span {
	opacity: 1;
}

/* --- Mini button --- */
.mini_btn {
	border: 2px solid #4f474e00;
	margin-bottom: 1px;
	border-radius: 5px;
	padding: 5px;
	width: 42px;
	height: 42px;
}

.mini_btn img {
	max-width: 25px;
	max-height: 25px;
}

.mini_btn:active {
	margin-top: 1px;
	margin-bottom: 0px;
}

.mini_btn:hover,
.mini_btn:active,
.mini_btn:focus {
	background-color: var(--button-back-secondary);
	border: 2px solid #4f474e61;
}

/* --- Input --- */
input.red:hover,
input.red:active,
input.red:focus {
	border: 2px solid var(--red-secondary) !important;
}

input.blue:hover,
input.blue:active,
input.blue:focus {
	border: 2px solid var(--blue-secondary) !important;
}

input.green:hover,
input.green:active,
input.green:focus {
	border: 2px solid var(--green-secondary) !important;
}

input.orange:hover,
input.orange:active,
input.orange:focus {
	border: 2px solid var(--orange-secondary) !important;
}

.is-red {
	color: var(--red-secondary);
}

.is-blue {
	color: var(--blue-secondary);
}

.is-green {
	color: var(--green-secondary);
}

.is-orange {
	color: var(--orange-secondary);
}

/* --- Form --- */
form {
	max-width: 360px;
	position: relative;
	text-align: center;
	z-index: 1;
}

.fluent_form {
	margin-left: 45px;
}

/* --- Input --- */
input:disabled,
textarea:disabled,
button:disabled {
	cursor: not-allowed !important;
}

input[type="text"],
input[type="number"],
input[type="password"] {
	width: calc(100% - 13px);
	height: 32px;
	display: block;
	box-sizing: content-box;

	color: var(--text);
	border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
	outline: 0;

	font: 700 17px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

textarea,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"] {
	background-color: var(--button-back-primary);
	border: 2px solid var(--transparent);
}

textarea:hover,
textarea:active,
textarea:focus,
input[type="text"]:hover,
input[type="text"]:active,
input[type="text"]:focus,
input[type="number"]:hover,
input[type="number"]:active,
input[type="number"]:focus,
input[type="password"]:hover,
input[type="password"]:active,
input[type="password"]:focus,
input[type="file"]:hover,
input[type="file"]:active,
input[type="file"]:focus {
	background-color: var(--button-back-secondary);
	border: 2px solid var(--border-color);
}

/* --- Fluent TextArea --- */
textarea {
	width: 96%;
	max-width: 96%;
	min-width: 96%;
	height: 60px;
	display: block;

	color: var(--text);
	border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
	outline: 0;

	font: 500 14px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* --- Form File --- */
input[type="file"] {
	width: calc(100% - 13px);
	height: 32px;
	margin: 0;
	display: block;
	box-sizing: content-box;

	color: var(--text);
	border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
	outline: 0;

	font: 700 17px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

input[type="text"].search {
	background: url("../img/search.svg") no-repeat right;
	background-size: 20px;
	background-color: var(--button-back-primary);
}


/* --- Input --- */

.select {
	box-sizing: content-box !important;
	color: var(--text) !important;
	margin: 5px 0 !important;
	font: 700 17px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
	height: 42px !important;

	border-radius: 5px !important;
	box-shadow: none !important;
	transition: none !important;
	background-color: var(--button-back-primary) !important;
	border: 2px solid var(--transparent) !important;
}

.select:hover,
.select.active {
	background-color: var(--button-back-secondary) !important;
	border: 2px solid var(--border-color) !important;
}

.select-list {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24) !important;
	border-radius: 5px !important;
	z-index: 5 !important;
	background-color: var(--el-back-secondary) !important;
	border: 2px solid var(--transparent) !important;
}

.select-option {
	border-radius: 5px !important;
	cursor: pointer !important;
	background: #fff0 !important;
}

.select-option:hover {
	background-color: #2674C620 !important;
}

.select-option.active {
	border-radius: 5px !important;
	background-color: var(--blue-primary) !important;
}

.select-separator {
	display: none;
}

.select-value {
	color: var(--text) !important;
}

.select-input {
	color: var(--text) !important;
}

/* --- React Toogle --- */
.react-toggle {
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	margin-right: 10px;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	vertical-align: middle;
	font-weight: normal;
	margin-bottom: 0;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: var(--el-back-primary);
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: var(--el-back-hover);
}

.react-toggle--checked .react-toggle-track {
	background-color: var(--blue-primary);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: var(--blue-secondary);
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 3px;
	left: 3px;
	width: 18px;
	height: 18px;
	border: 1px solid var(--secondary);
	border-radius: 50%;
	background-color: #FAFAFA;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: var(--blue-primary);
}

/* --- checkmark --- */
.checkbok-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 15px;
	text-align: left;
}

.checkbok-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 5px;
	background-color: var(--button-back-primary);
	border: 2px solid var(--transparent);
}

.checkmark:hover {
	background-color: var(--button-back-primary);
	border: 2px solid var(--border-color);
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbok-label {
	position: relative;
	top: 4px;
}

.checkbok-container input:checked~.checkmark:after {
	display: block;
}

.checkbok-container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.checkbok-container:hover input~.checkmark {
	background-color: var(--el-back-hover)
}

.checkbok-container input:checked~.checkmark {
	background-color: var(--blue-primary)
}

.checkbok-container:hover input:checked~.checkmark {
	background-color: var(--blue-secondary)
}

.checkbok-container input:disabled~.checkmark {
	background-color: #606060 !important;
	cursor: not-allowed !important;
}
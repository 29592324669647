body {
  overflow: hidden;
}

table {
  display: block;
  max-height: calc(100vh - 190px);
  overflow-y: scroll;
  overflow-x: hidden;
}

thead,
tbody {
  width: 100%;
  display: inline-table;
}

header {
  height: 50px;
  padding: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  color: #fff;
  background-color: #820282;
  z-index: 3;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

header img {
  height: 50px;
}

header span {
  font-weight: 300;
  font-size: 20px;
  position: relative;
  left: 15px;
}

header div, .upload_header .center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.path-link {
  color: #868686;
}

.path-link:hover {
  color: var(--text);
  transition: color 500ms;
}

.path-space {
  margin: 0 7px;
}

.upload_header {
  background-color: var(--blue-primary);
  height: 50px;
  padding: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload_header img {
  height: 50px;
  margin-right: 20px;
}

.upload_header>* {
  z-index: 5;
  font-size: 18px;
}

.upload_perc {
  background-color: var(--blue-high);
}

.upload_success {
  background-color: var(--green-high);
  width: 100%;
  transition: all .5s ease;
}

.upload_perc,
.upload_success {
  height: 50px;
  padding: 5px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: all .5s ease;
}

.explorer {
  height: 100vh;
}

.explorer .pane {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  background: var(--el-back-secondary);
  border-right: 2px solid #4b4b4b;
  padding-top: 10px;
}


.explorer .pane button:hover {
  background-color: var(--el-back-hover);
  border: 2px solid var(--border-color);
}

.explorer .pane button:active {
  margin-top: 1px;
  margin-bottom: 0px;
}

.explorer .pane .footer {
  margin-top: auto;
  border-top: 2px solid #4b4b4b;
}

hr {
  margin: 0;
  border-bottom: 2px solid var(--secondary);
}

.options {
  margin: 10px;
  margin-right: 0px;
}

.nav {
  display: flex;
}



.files {
  padding: 20px;
}

.files img {
  width: 25px;
}

.files th {
  text-align: left;
}

/* --- Loader --- */

.loader-4 {
  --color: #820282;
}

.loader-4 {
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: 1vmin;
  --size-square: 10px;
  position: relative;
  width: 100%;
  display: block;
}

.loader-4::before,
.loader-4::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
}

.loader-4::before {
  height: var(--size-bar);
  width: 100%;
  background-color: var(--color);
  animation: loader-4 2s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes loader-4 {

  0%,
  44%,
  88.1%,
  100% {
    transform-origin: left;
  }

  0%,
  100%,
  88% {
    transform: scaleX(0);
  }

  44.1%,
  88% {
    transform-origin: right;
  }

  33%,
  44% {
    transform: scaleX(1);
  }
}

/* --- Path --- */

.path {
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid var(--transparent);
  display: inline-block;
  height: 39px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0 10px;
  width: 100%;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.path:active {
  margin-top: 1px;
}

.path:hover,
.path:active,
.path:focus {
  background-color: #0000000a;
  border: 2px solid #4f474e61;
}

/* --- Tree Node --- */

.tree {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tree-node {
  cursor: pointer;
  border: 2px solid var(--transparent);
  transition: all 500ms;
  height: 31px;
  display: flex;
  align-items: center;
}

.tree-node:active {
  margin-top: 1px;
}

.tree-node:hover,
.tree-node:active,
.tree-node:focus {
  border: 2px solid var(--border-color);
  background-color: var(--el-back-hover);
  color: var(--text);
}

.tree-node img {
  width: 16px;
  margin: 0 5px;
}

.tree-node.current {
  background-color: var(--select-hover);
  color: var(--text);
}

.tree-node.current:hover {
  background-color: var(--select-hover2);
}

.tree-arrow {
  min-width: 16px;
}

.tree-node .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.tree-arrow {
  transition: all 200ms;
}

.tree-link {
  display: block;
  width: 100%;
}

.tree-child {
  padding-left: 20px;
}

.tree-nochild {
  padding-left: 16px;
}

/* --- Files --- */

.files table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
}

.files tbody tr {
  border-radius: 10px;
  cursor: pointer;
  padding: 12px 0 0 0;
  border: 2px solid var(--transparent);
  color: #868686;
  overflow: hidden;
  font-size: 15px;
  word-wrap: anywhere;
}

.files tbody tr:active {
  margin-top: 1px;
}

.files tbody tr span {
  margin-left: 10px;
  position: relative;
  top: -7px;
}

.files tbody tr:hover {
  background-color: var(--el-back-hover);
  transition: color 500ms;
  color: var(--text);
}

.files tbody tr.selected {
  background-color: var(--select);
  color: var(--text);
}

.files tbody tr.selected:hover {
  background-color: var(--select-hover);
}

/* --- --- */

.files tbody td:first-child,
.files thead th:first-child {
  width: 30px;
  text-align: center;
}

.files tbody td:nth-child(2) {
  width: 35px;
  text-align: center;
}

.files tbody td:nth-child(4),
.files thead th:nth-child(4) {
  width: 80px;
  text-align: left;
}

.files tbody td:nth-child(5),
.files thead th:nth-child(5) {
  width: 190px;
  text-align: left;
}

.files tbody td:nth-child(6),
.files thead th:nth-child(6) {
  width: 60px;
  text-align: left;
}

/* --- --- */

.explorer-space {
  border: 1px solid #820282;
}

.space {
  border: 1px solid var(--text);
}

.options-space {
  background-color: #777;
  border-radius: 5px;
  width: 2px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 5px;
}

/* --- --- */

.is-hidden {
  opacity: 0;
}

.center {
  text-align: center;
}

ul {
  padding: 0;
}

li {
  padding: 0;
}

.visually-hidden {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}



.files tbody tr input:checked~.tr {
  color: red
}


/*------- Toogle -------*/

.toggle-control {
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 50px;
  border-radius: 12.5px;
  -webkit-transition: background-color 0.15s ease-in;
  transition: background-color 0.15s ease-in;
}

.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 12.5px;
  background: white;
  -webkit-transition: left 0.15s ease-in;
  transition: left 0.15s ease-in;
}


.toggle-control .control {
  background-color: var(--el-back-secondary);
}

.toggle-control .control:after {
  background: #fff;
}

.toggle-control.checked .control {
  background-color: var(--blue-primary);
}

.toggle-control.checked .control:after {
  left: 30px;
}

.toogle-div label {
  position: relative;
  top: -5px;
  margin-left: 10px;
}

.progress-bck {
  background-color: var(--acrylic-light);
  border-radius: 5px;
  float: left;
  height: 1vmin;
  position: relative;
  width: 100%
}

.progress {
  background-color: #820282;
  border-radius: 5px;
  float: left;
  height: 1vmin;
  position: relative;
  top: -1vmin;
  transition: all .5s;
  width: 0
}

.mini_progress {
  background-color: #820282;
  border-radius: 5px;
  float: left;
  height: 0.5vmin;
  position: relative;
  transition: all .5s;
  width: 0
}

.indeterminate {
  animation: indeterminate 1.3s ease infinite
}

@keyframes indeterminate {
  0% {
    left: 0;
    width: 0
  }

  50% {
    left: 0;
    width: 100%
  }

  100% {
    left: 100%;
    width: 0
  }
}

h2 {
  margin: 10px;
}

.storage-bar {
  height: 1vmin;
  position: relative;
  border-radius: 1vmin;
  transition: all 0.8s;
  min-width: 1vmin;
  animation: storage-bar 1.5s ease;
}

.round_size {
  border-radius: 5px;
  display: inline-block;
  height: 20px;
  position: relative;
  top: 3px;
  width: 20px;
}

@keyframes storage-bar {
  0% {
    max-width: 2vmin;
  }

  100% {
    max-width: 100%;
  }
}
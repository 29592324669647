body {
    background-color: var(--body);
    color: var(--text);
    font-family: Luciole, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    overflow-x: hidden
}

a {
    color: inherit;
    text-decoration: none;
    outline: 0
}

.content {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 62px);
    top: 62px;
    overflow: scroll;
}

/* --- Link --- */
.link {
    border-bottom: 0px solid #0000;
    transition: all .2s;
    cursor: pointer;
}

.link:hover {
    border-bottom: 2px solid var(--text);
    transition: all .2s;
}

.link_blue {
    color: var(--blue-primary);
    border-bottom: 0px solid #0000;
    transition: all .2s;
    cursor: pointer;
}

.link_blue:hover {
    border-bottom: 2px solid var(--blue-primary);
    transition: all .2s;
}

.link_white {
    color: #fff;
    border-bottom: 0px solid #fff0;
    transition: all .2s;
    cursor: pointer;
}

.link_white:hover {
    border-bottom: 2px solid #fff;
    transition: all .2s;
}

.link_dark {
    color: #000;
    border-bottom: 0px solid #0000;
    transition: all .2s;
    cursor: pointer;
}

.link_dark:hover {
    border-bottom: 2px solid #000;
    transition: all .2s;
}

/* --- SVG --- */

.svg_white {
    filter: invert(100%) sepia(93%) saturate(27%) hue-rotate(39deg) brightness(107%) contrast(107%)
}

.svg_black {
    filter: invert(0) sepia(8%) saturate(0) hue-rotate(83deg) brightness(96%) contrast(106%)
}

h1,
h2,
h3,
h4 {
    margin-bottom: 10px;
    margin-top: 10px
}

.modal-back {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 30, 30, 0.8);
    backdrop-filter: blur(2px);
    z-index: 5;
}

.modal {
    border-radius: 15px;
    bottom: 0;
    display: block;
    height: calc(100vh - 40px);
    left: 0;
    margin: auto;
    max-height: 450px;
    max-width: 500px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100vw - 40px);
    z-index: 100;
    background-color: var(--el-back-secondary);
}

.mini-modal {
    display: block;
    position: relative;

    top: 25vh;
    left: 0;
    right: 0;
    bottom: 0;

    width: calc(100vw - 40px);
    max-width: 500px;
    height: calc(100vh - 40px);
    max-height: 200px;

    margin: auto;
    padding: 20px;

    background-color: var(--el-back-secondary);
    border-radius: 10px;
}

.small-modal {
    display: block;
    position: relative;

    top: 25vh;
    left: 0;
    right: 0;
    bottom: 0;

    width: calc(100vw - 40px);
    max-width: 500px;
    height: calc(100vh - 40px);
    max-height: 300px;

    margin: auto;
    padding: 20px;

    background-color: var(--el-back-secondary);
    border-radius: 10px;
}

.modal .footer,
.mini-modal .footer,
.small-modal .footer {
    position: absolute;
    width: 93%;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    background-color: var(--el-back-secondary);
}

.modal .scrollable {
    position: initial;
    overflow-y: scroll;
    max-height: calc(100% - 100px);
}

/* --- Block --- */
.box {
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
}



div.green,
div.blue,
div.orange,
div.red,
div.grey {
    border-radius: 5px;
    margin: 10px;
    padding: 10px;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

div.green img,
div.blue img,
div.orange img,
div.red img,
div.grey img {
    max-width: 30px;
}

div.green {
    background-color: var(--green-back);
    border: 2px solid var(--green-primary);
    color: var(--green-primary);
}

div.blue {
    background-color: var(--blue-back);
    border: 2px solid var(--blue-primary);
    color: var(--blue-primary);
}

div.orange {
    background-color: var(--orange-back);
    border: 2px solid var(--orange-primary);
    color: var(--orange-primary);
}

div.red {
    background-color: var(--red-back);
    border: 2px solid var(--red-primary);
    color: var(--red-primary);
}

.doc {
    max-width: 800px;
    margin: auto;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.m-3 {
    margin: 0.75rem !important;
}

.mt-3 {
    margin-top: 0.75rem !important;
}

.mr-3 {
    margin-right: 0.75rem !important;
}

.mb-3 {
    margin-bottom: 0.75rem !important;
}

.ml-3 {
    margin-left: 0.75rem !important;
}

.mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}

.my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}

.m-4 {
    margin: 1rem !important;
}

.mt-4 {
    margin-top: 1rem !important;
}

.mr-4 {
    margin-right: 1rem !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

.ml-4 {
    margin-left: 1rem !important;
}

.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.m-5 {
    margin: 1.5rem !important;
}

.mt-5 {
    margin-top: 1.5rem !important;
}

.mr-5 {
    margin-right: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 1.5rem !important;
}

.ml-5 {
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.my-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.m-6 {
    margin: 3rem !important;
}

.mt-6 {
    margin-top: 3rem !important;
}

.mr-6 {
    margin-right: 3rem !important;
}

.mb-6 {
    margin-bottom: 3rem !important;
}

.ml-6 {
    margin-left: 3rem !important;
}

.mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.p-3 {
    padding: 0.75rem !important;
}

.pt-3 {
    padding-top: 0.75rem !important;
}

.pr-3 {
    padding-right: 0.75rem !important;
}

.pb-3 {
    padding-bottom: 0.75rem !important;
}

.pl-3 {
    padding-left: 0.75rem !important;
}

.px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.p-4 {
    padding: 1rem !important;
}

.pt-4 {
    padding-top: 1rem !important;
}

.pr-4 {
    padding-right: 1rem !important;
}

.pb-4 {
    padding-bottom: 1rem !important;
}

.pl-4 {
    padding-left: 1rem !important;
}

.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-5 {
    padding: 1.5rem !important;
}

.pt-5 {
    padding-top: 1.5rem !important;
}

.pr-5 {
    padding-right: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 1.5rem !important;
}

.pl-5 {
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-6 {
    padding: 3rem !important;
}

.pt-6 {
    padding-top: 3rem !important;
}

.pr-6 {
    padding-right: 3rem !important;
}

.pb-6 {
    padding-bottom: 3rem !important;
}

.pl-6 {
    padding-left: 3rem !important;
}

.px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.p-auto {
    padding: auto !important;
}

.pt-auto {
    padding-top: auto !important;
}

.pr-auto {
    padding-right: auto !important;
}

.pb-auto {
    padding-bottom: auto !important;
}

.pl-auto {
    padding-left: auto !important;
}

.px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
}

.py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
}

/* ---------------------------------------------------------------- */

.left {
    text-align: left !important;
}

.center {
    text-align: center !important;
}

.right {
    text-align: right !important;
}

.justify {
    text-align: justify !important;
}

/* ---------------------------------------------------------------- */
.is-white {
    color: #fff !important;
}

.id-dark {
    color: #000 !important;
}

/* --- Atribute --- */
/* Position */

.is-static {
    position: static !important;
}

.is-relative {
    position: relative !important;
}

.is-absolute {
    position: absolute !important;
}

.is-fixed {
    position: fixed !important;
}

.is-sticky {
    position: sticky !important;
}

/* Display */
.is-block {
    display: block !important;
}

.is-inline {
    display: inline !important;
}

.is-table {
    display: table !important;
}

.is-flex {
    display: flex !important;
}

.is-grid {
    display: grid !important;
}

.is-inline-block {
    display: inline-block !important;
}

.is-inline-table {
    display: inline-table !important;
}

.is-inline-flex {
    display: inline-flex !important;
}

.is-inline-grid {
    display: inline-grid !important;
}

.is-flex-wrap {
    flex-wrap: wrap !important;
}

/* --- Flex --- */
.is-flex-direction-row {
    flex-direction: row !important
}

.is-flex-direction-row-reverse {
    flex-direction: row-reverse !important
}

.is-flex-direction-column {
    flex-direction: column !important
}

.is-flex-direction-column-reverse {
    flex-direction: column-reverse !important
}

.is-align-items-stretch {
    align-items: stretch !important
}

.is-align-items-flex-start {
    align-items: flex-start !important
}

.is-align-items-flex-end {
    align-items: flex-end !important
}

.is-align-items-center {
    align-items: center !important
}

.is-align-items-baseline {
    align-items: baseline !important
}

.is-align-items-start {
    align-items: start !important
}

.is-align-items-end {
    align-items: end !important
}

.is-align-items-self-start {
    align-items: self-start !important
}

.is-align-items-self-end {
    align-items: self-end !important
}

.is-align-self-auto {
    align-self: auto !important
}

.is-align-self-flex-start {
    align-self: flex-start !important
}

.is-align-self-flex-end {
    align-self: flex-end !important
}

.is-align-self-center {
    align-self: center !important
}

.is-align-self-baseline {
    align-self: baseline !important
}

.is-align-self-stretch {
    align-self: stretch !important
}


.is-pointer {
    cursor: pointer !important;
}

.is-grab {
    cursor: grab !important;
}

.space-between {
    justify-content: space-between !important
}